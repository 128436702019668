import React, { useEffect, useMemo, useCallback } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { get, sortBy, head } from '../../../lib/nodash';
import { useDispatch } from 'react-redux';
import 'lazysizes';
import { Router } from '@reach/router';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

import { useStoryblokState } from '../../../lib/storyblok';
import PageGallery from '../../../components/Gallery/PageGallery';
import Seo from '../../../components/Seo';
import deNodify from '../../../lib/deNodify';
import usePageBodyComponents from '../../../components/DynamicPage/usePageBodyComponents';
import { setPaintCollections } from '../../../state/product/productSlice';
import { toggleFilterPanel } from '../../../state/ui/uiSlice';
import { extractAllFilters } from '../../../lib/filters/filterRouteHelpers';

const Gallery = ({
  data: {
    storyblokEntry,
    paintCollections,
    colorGroup,
    colors,
    spaceGroup,
    featuredGroup,
    productTypeGroup,
    gallery,
    paintFilters,
    site,
  },
}) => {
  const defaultPaintFilters = useStoryblokState(paintFilters);
  const colorOrder = useMemo(
    () =>
      (
        get(
          'filters',
          head(get('content.filterGroups', defaultPaintFilters))
        ) || []
      ).map(({ slug }) => slug),
    [defaultPaintFilters]
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const featuredFilters = get('tags', featuredGroup) || [];
  const colorFilters = useMemo(
    () =>
      (get('tags', colorGroup) || []).sort((a, b) => {
        return colorOrder.indexOf(a.slug) - colorOrder.indexOf(b.slug);
      }),
    [colorOrder, colorGroup]
  );
  const spaceFilterTags = get('tags', spaceGroup) || [];
  const productTypeTags = get('tags', productTypeGroup) || [];
  const spaceFilterTagGroups = get('tag_groups', spaceGroup) || [];
  const story = useStoryblokState(storyblokEntry);
  const handlePanelToggle = useCallback(() => {
    dispatch(toggleFilterPanel());
  }, [dispatch, toggleFilterPanel]);

  useEffect(() => {
    dispatch(setPaintCollections(deNodify(paintCollections)));
  }, [paintCollections]);

  const spaceFilters = sortBy('name', [
    ...spaceFilterTags,
    ...spaceFilterTagGroups,
  ]);
  const productTypeFilters = sortBy('name', productTypeTags);

  const allFilterCriteria = [
    ...productTypeFilters,
    ...featuredFilters,
    ...colorFilters,
    ...spaceFilters,
    ...deNodify(colors),
  ];

  const title =
    get('content.metafields.title', story) ||
    get('title', story) ||
    get('field_title_string', storyblokEntry);

  const searchParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  const activeFilters = useMemo(
    () => extractAllFilters(searchParams),
    [searchParams]
  );

  const content = usePageBodyComponents(story, {
    rowsFillContainerHeight: true,
    onToggleFilterPanel: handlePanelToggle,
    activeFilters,
  });

  return (
    <>
      <Seo
        title={title}
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        canonical={`${site.siteMetadata.siteUrl}/pages/gallery/`}
      />
      <Router basepath="/gallery">
        <PageGallery
          default={true}
          galleryId={get('strapiId', gallery)}
          colors={deNodify(colors)}
          featuredFilters={featuredFilters}
          colorFilters={colorFilters}
          productTypeFilters={productTypeFilters}
          spaceFilters={spaceFilters}
          spaceFilterTagGroups={spaceFilterTagGroups}
          allFilterCriteria={allFilterCriteria}
          content={content}
          title={title}
        />
      </Router>
    </>
  );
};

Gallery.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

export default Gallery;

export const query = graphql`
  query GalleryPage {
    storyblokEntry(full_slug: { eq: "pages/gallery" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    paintCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { eq: "Paint" }
          }
        }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          id
          title
          handle
          description
          shopifyId
          products {
            shopifyId
            productType
          }
          metafields {
            type
            key
            value
            namespace
          }
        }
      }
    }

    colorWheel: strapiColorWheel {
      Colors {
        id
        color {
          id
          slug
          shopify_product_ids
        }
      }
    }

    gallery: strapiMainGalleries(identifier: { eq: "main" }) {
      id
      strapiId
    }

    featuredGroup: strapiTagGroup(slug: { eq: "gallery-featured" }) {
      __typename
      id
      strapiId
      name
      tags {
        __typename
        name
        id
        slug
      }
    }

    spaceGroup: strapiTagGroup(slug: { eq: "gallery-spaces" }) {
      __typename
      id
      strapiId
      name
      tags {
        __typename
        name
        id
        slug
      }
      tag_groups {
        __typename
        id
        name
        slug
      }
    }

    colorGroup: strapiTagGroup(slug: { eq: "color" }) {
      __typename
      id
      strapiId
      name
      tags {
        __typename
        name
        id
        slug
      }
    }

    productTypeGroup: strapiTagGroup(slug: { eq: "product-types" }) {
      __typename
      id
      strapiId
      name
      tags {
        __typename
        name
        id
        slug
      }
    }

    colors: allStrapiColorsByColorWheel {
      edges {
        node {
          id
          strapiId
          __typename
          name
          hex_value
          slug
          tags {
            __typename
            id
            name
            slug
          }
        }
      }
    }

    paintFilters: storyblokEntry(
      full_slug: { eq: "filters/default-paint-filters" }
    ) {
      content
      id
      slug
      uuid
      internalId
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
